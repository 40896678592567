import styled from "@emotion/styled";

export interface ShapeProps {
    color: string;
    opacity?: number;
    rotate?: number;
    scale?: number;
    translateX?: number;
    translateY?: number;
}

const StyledPath = styled.path((props: { pathColor: string, shapeOpacity: number }) => `
    fill: ${props.pathColor};
    fill-opacity: ${props.shapeOpacity};
`);

export const ShapeDown: React.FC<ShapeProps> = (props) => {
    const op = (props.opacity || 100) / 100;

    var transform = '';
    if (props.rotate) {
        transform = `rotate(${props.rotate} 565 480)`;
    }

    if (props.scale) {
        transform = `${transform} scale(${props.scale})`;
    }

    if (props.translateX || props.translateY) {
        transform = `${transform} translate(${props.translateX || 0} ${props.translateY || 0})`;
    }

    return (
        <g transform={transform}>
            <StyledPath pathColor={props.color} shapeOpacity={op}
                d="m563.6,3.76c109.63,0 219.26,-0.45 328.87,0.53c26.69,0.24 53.43,3.39 79.44,11.6c37.9,12 68.89,32.2 95.76,58.94c26.61,26.5 43.7,57.58 53.7,92a169.65,169.65 0 0 1 4.37,77.77a218.48,218.48 0 0 1 -29.5,77.13c-17.46,28.1 -34.56,56.4 -51.8,84.62c-8.16,13.36 -15.14,27.48 -24.75,39.89c-10.64,13.73 -15.57,30.48 -26.82,43.22s-17.83,28 -28,41.27c-6.26,8.16 -9.47,18.23 -14.91,27c-8.25,13.28 -17.31,26.14 -25.91,39.26c-2.49,3.79 -4.42,7.89 -6.83,11.73c-4.61,7.36 -9.43,14.6 -14,21.94c-4.7,7.5 -9.23,15.08 -13.92,22.59c-7.47,12 -14.87,24 -22.55,35.89c-12.73,19.69 -25.8,39.2 -38.45,58.94c-7.6,11.87 -14.38,24.18 -22,36q-26,40.32 -52.55,80.35c-14.46,21.86 -34,39.48 -56.29,54.46c-24.7,16.61 -52.23,27.67 -82.22,33.58c-18.34,3.61 -36.81,6.54 -55.59,5.72c-37.88,-1.66 -74.1,-9.58 -107.36,-26.83a223.86,223.86 0 0 1 -86.51,-79.05q-36,-56 -71.29,-112.32c-27,-43 -53.77,-86.06 -80.66,-129.08q-37.44,-59.9 -74.93,-119.76c-20.94,-33.35 -42.26,-66.48 -63,-99.93c-17.42,-28.12 -34.74,-56.32 -51.12,-84.95c-9.38,-16.4 -14.12,-34.4 -18.81,-52.51c-5,-19.4 -5,-38.7 -3.21,-58c1.6,-17.44 4.82,-34.94 12.83,-51.14s14.78,-33.06 25.18,-47.8a209.69,209.69 0 0 1 75.44,-65.45c22.34,-11.62 46.35,-19.87 72,-23.19c32.13,-4.15 64.44,-4.9 96.87,-4.76c92.79,0.49 185.66,0.3 278.52,0.34z" />
        </g>
    );
}

export interface ClipPathProps {
    name: string;
}

export const ClipPath: React.FC<ClipPathProps> = (props) => {
    return (<svg height="0" width="0" viewBox="0 0 1130 960">
        <defs>
            <clipPath id={props.name} clipPathUnits="objectBoundingBox"
                transform="scale(0.00088495575, 0.00104166666)">
                <path fill="#FFFFFF" stroke="#000000" strokeWidth="1.5794" strokeMiterlimit="10" d="m563.6,3.76c109.63,0 219.26,-0.45 328.87,0.53c26.69,0.24 53.43,3.39 79.44,11.6c37.9,12 68.89,32.2 95.76,58.94c26.61,26.5 43.7,57.58 53.7,92a169.65,169.65 0 0 1 4.37,77.77a218.48,218.48 0 0 1 -29.5,77.13c-17.46,28.1 -34.56,56.4 -51.8,84.62c-8.16,13.36 -15.14,27.48 -24.75,39.89c-10.64,13.73 -15.57,30.48 -26.82,43.22s-17.83,28 -28,41.27c-6.26,8.16 -9.47,18.23 -14.91,27c-8.25,13.28 -17.31,26.14 -25.91,39.26c-2.49,3.79 -4.42,7.89 -6.83,11.73c-4.61,7.36 -9.43,14.6 -14,21.94c-4.7,7.5 -9.23,15.08 -13.92,22.59c-7.47,12 -14.87,24 -22.55,35.89c-12.73,19.69 -25.8,39.2 -38.45,58.94c-7.6,11.87 -14.38,24.18 -22,36q-26,40.32 -52.55,80.35c-14.46,21.86 -34,39.48 -56.29,54.46c-24.7,16.61 -52.23,27.67 -82.22,33.58c-18.34,3.61 -36.81,6.54 -55.59,5.72c-37.88,-1.66 -74.1,-9.58 -107.36,-26.83a223.86,223.86 0 0 1 -86.51,-79.05q-36,-56 -71.29,-112.32c-27,-43 -53.77,-86.06 -80.66,-129.08q-37.44,-59.9 -74.93,-119.76c-20.94,-33.35 -42.26,-66.48 -63,-99.93c-17.42,-28.12 -34.74,-56.32 -51.12,-84.95c-9.38,-16.4 -14.12,-34.4 -18.81,-52.51c-5,-19.4 -5,-38.7 -3.21,-58c1.6,-17.44 4.82,-34.94 12.83,-51.14s14.78,-33.06 25.18,-47.8a209.69,209.69 0 0 1 75.44,-65.45c22.34,-11.62 46.35,-19.87 72,-23.19c32.13,-4.15 64.44,-4.9 96.87,-4.76c92.79,0.49 185.66,0.3 278.52,0.34z" />
            </clipPath>
        </defs>
    </svg>);
}

export interface ShapeAreaProps {
    fixedWidth?: number;
    marginTop?: number;
    marginBottom?: number;
}

const Area = styled.div((props: ShapeAreaProps) => {
    let fixedWidth = ``;
    if (props.fixedWidth) {
        fixedWidth = `max-width: ${props.fixedWidth}px; margin: auto;`;
    }

    return `
    position: relative;
    margin-top: ${props.marginTop !== undefined ? props.marginTop : 75}px;
    margin-bottom: ${props.marginBottom !== undefined ? props.marginBottom : 50}px;
    ${fixedWidth}
`});

export const ShapeArea: React.FC<ShapeAreaProps> = (props) => {
    return <Area {...props}>{props.children}</Area>
}

export interface ShapeStackProps {
    top?: number;
    left?: number;
    zIndex?: number;
}

const PositionedSvg = styled.svg((props: ShapeStackProps) => `
    position: absolute;
    top: ${props.top || 0}px;
    left: ${props.left || 0}px;
    overflow: visible;
    z-index: ${props.zIndex || 'auto'};
`);

export const ShapeStack: React.FC<ShapeStackProps> = (props) => {
    return <PositionedSvg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1130 960">
        {props.children}
    </PositionedSvg>;
}